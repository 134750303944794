<!--
  后台发布服务
 -->
<template>
  <div class="detail">
    <detail-header title="发布服务" />
    <div class="detail_main page_width">
      <div class="main_content" v-loading="loading">
        <!-- 选择类型 -->
        <el-form
          ref="serviceTypeForm"
          :model="serviceTypeForm"
          :rules="serviceTypeRules"
          size="small"
          label-width="180px"
          label-position="left">
          <el-form-item
            label="三级服务类型"
            prop="serviceId">
            <el-cascader
              style="width: 380px;"
              v-model="serviceTypeForm.serviceId"
              :options="serviceOptions"
              :props="{
                value: 'id',
                label: 'name',
                emitPath: false
              }"
              @change="serviceIdChange">
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="关联服务机构"
            prop="proxyOrgId">
            <el-select
              style="width: 380px;"
              v-model="serviceTypeForm.proxyOrgId"
              filterable
              remote
              placeholder="请输入机构名称搜索"
              :remote-method="searchOrg"
              :loading="orgSelectLoading">
              <el-option
                v-for="item in orgOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 自定义表单 -->
        <custom-form
          ref="customForm"
          mode="enter"
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
          </custom-form-item>
        </custom-form>

        <div class="btn_box">
          <el-button type="primary" size="small" :loading="btnLoading" @click="submitForm">发布服务</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      loading: false,
      // 选择机构的选择器
      orgSelectLoading: false,
      // 提交按钮的Loading
      btnLoading: false,
      selectOrgDialogVisible: false,
      // 选择服务类型表单
      serviceTypeForm: {
        serviceId: '',
        proxyOrgId: '' // 关联的服务机构
      },
      serviceTypeRules: {
        serviceId: [
          { required: true, message: '请选择三级服务类型', trigger: 'change' }
        ],
        proxyOrgId: [
          { required: true, message: '请选择所关联的服务机构', trigger: 'change' }
        ]
      },
      // 服务类型选项
      serviceOptions: [],
      // 机构列表选项
      orgOptions: [],
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  components: {
    customForm,
    customFormItem
  },
  created () {
    // 获取服务类型阶梯列表
    this.getServiceTypeList()
  },
  methods: {
    // 获取服务类型阶梯列表 做级联
    getServiceTypeList () {
      api.getServiceTypeCheckList().then(res => {
        if (res.data.code === 0) {
          console.log('服务类型阶梯', res.data.data)
          this.serviceOptions = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取表单的详情
    getFormByService () {
      this.loading = true
      api.getFormByService(this.serviceTypeForm.serviceId).then(res => {
        if (res.data.code === 0) {
          console.log('服务表单', res.data.data)
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 根据关键词来搜索结构
    getKeywordsDownOrgList (value) {
      this.orgSelectLoading = true
      api.getKeywordsDownOrgList({
        keywords: value
      }).then(res => {
        if (res.data.code === 0) {
          console.log('机构列表', res.data.data)
          this.orgOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.orgSelectLoading = false
      })
    },
    // 发布新服务
    createService (data) {
      this.btnLoading = true
      api.createService({
        ...data,
        proxyOrgId: this.serviceTypeForm.proxyOrgId
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('发布服务成功！')
          this.$router.replace('/resource-require/service')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },

    /**
     * 服务发生变化
     *  + 重新获取表单
     */
    serviceIdChange (value) {
      this.getFormByService()
    },
    // 搜索机构
    searchOrg (value) {
      if (value !== '') {
        this.getKeywordsDownOrgList(value)
      } else {
        this.orgOptions = []
      }
    },
    // 提交表单
    submitForm () {
      this.$refs.serviceTypeForm.validate((valid) => {
        if (valid) {
          this.validateCustomForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证自定义表单
    async validateCustomForm () {
      try {
        const data = await this.$refs.customForm.validate()
        const fixedData = {}
        const columnData = {}
        // 把固定字段表单项和非固定自定表单项值隔离开来
        this.fieldList.forEach(item => {
          if (item.isFixed) {
            fixedData[item.column] = data[item.column]
          } else {
            columnData[item.column] = data[item.column]
          }
        })
        // 创建服务所需要的数据
        const serviceData = {
          service: {
            serviceTypeId: this.serviceTypeForm.serviceId,
            ...fixedData
          },
          formData: {
            ...columnData
          }
        }
        console.log('serviceData', serviceData)
        this.createService(serviceData)
      } catch (err) {
        console.log(err)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .btn_box {
        padding: 30px 0 0 180px;
      }
    }
  }
}
</style>
